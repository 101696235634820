import { COLLECTION_TYPE } from "../assets/models/iCollection";
import { NFT_SELL_STATUS } from "../assets/models/iNft";

import promoBG from "../assets/img/wall/promo.jpg";

const partnerFilterHotelDefault = {
  isActive: true,
  "censusStats.averageBookingPrice": { $exists: true },
  "census.note": { $exists: true },
  "census.name": { $exists: true },
  images: { $exists: true, $not: { $size: 0 } },
};

const partnerFilterExperienceDefault = {
  isActive: true,
  "census.name": { $exists: true },
  images: { $exists: true, $not: { $size: 0 } },
};

const isHotelTak = {
  $or: [
    { "payload.type": { $exists: false } },
    { "payload.type": COLLECTION_TYPE.hotel },
  ],
};

const isExperienceTak = {
  "payload.type": COLLECTION_TYPE.experience,
};

export enum WALL_SLOT_TYPE {
  CLASSIC = "CLASSIC",
  PARTNER = "PARTNER",
  LAST_MINUTE = "LAST_MINUTE",
  STAY_REQUEST = "STAY_REQUEST",
  PROMO = "PROMO",
  OFFER = "OFFER",
  SOCIAL = "SOCIAL",
}

export type WallSlotClassic = {
  query: object;
  limit?: number;
  sort?: object;
  scroll?: boolean;
};
export type WallSlotPartners = {
  query: object;
  hidePartnerModal?: boolean;
  limit?: number;
  sort?: object;
};
type WallSlotStayRequest = {};
type WallSlotSocial = {};

export type WallSlotLastMinute = {
  query: object;
  sort?: object;
  limit?: number;
  action?: () => void;
};

export type WallSlotPromo = {
  title: string;
  subtitle: string;
  background: string;
  action?: string;
};
export type WallSlotOffer = {
  query: object;
  limit?: number;
  sort?: object;
  action?: () => void;
};

export type WallSlotTypePayload =
  | { type: WALL_SLOT_TYPE.CLASSIC; payload: WallSlotClassic }
  | { type: WALL_SLOT_TYPE.PARTNER; payload: WallSlotPartners }
  | { type: WALL_SLOT_TYPE.LAST_MINUTE; payload: WallSlotLastMinute }
  | { type: WALL_SLOT_TYPE.STAY_REQUEST; payload: WallSlotStayRequest }
  | { type: WALL_SLOT_TYPE.PROMO; payload: WallSlotPromo }
  | { type: WALL_SLOT_TYPE.OFFER; payload: WallSlotOffer }
  | { type: WALL_SLOT_TYPE.SOCIAL; payload: WallSlotSocial };

export interface iWallSlot {
  title: string;
  subtitle?: string;

  config: WallSlotTypePayload;
}

export type iWallConfigObject = {
  area: string;
  icon: string;
  separator?: boolean;
  categoryName: string;
  showFilter?: boolean;
  slots: iWallSlot[];
};

export type iWallConfig = {
  area: string;
  icon: string;
  separator?: boolean;
  categoryName: string;
  showFilter?: boolean;
  slots: iWallSlot[];
}[];

export interface iWall {
  name: string;
  enabled: boolean;
  config: iWallConfig;
}

export const WALL_CONFIG_PROD: iWall = {
  name: "default",
  enabled: true,

  config: [
    {
      area: "all",
      categoryName: "wall.all",
      icon: "explore",
      showFilter: false,
      // separator: true,
      slots: [
        {
          title: "wall_sections.main.classic_hero",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              scroll: true,
              query: { ...isHotelTak, sellStatus: NFT_SELL_STATUS.FOR_SALE },
              sort: { lockDate: -1 },
            },
          },
        },
        {
          title: `wall_sections.main.promo_1`,
          config: {
            type: WALL_SLOT_TYPE.PROMO,
            payload: {
              title: "wall_sections.main.promo_2",
              subtitle: "wall_sections.main.promo_3",
              background: promoBG,
              action: "/experience",
            },
          },
        },
        {
          title: "wall_sections.main.experience",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              scroll: true,
              query: {
                ...isExperienceTak,
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
              },
              sort: { lockDate: -1 },
              limit: 4,
            },
          },
        },
        {
          title: `wall_sections.main.partners_1`,
          config: {
            type: WALL_SLOT_TYPE.PARTNER,
            payload: {
              query: {
                ...partnerFilterHotelDefault,
                _id: {
                  $in: [
                    "a8472659-8b30-4e13-abe7-990fc68da6af",
                    "4a3ff12a-fd66-49d0-92d3-a7640a38dcb8",
                    "43cd6a64-f6c0-4873-b316-f3ac83325cd1",
                    "68d63e6e-7cf9-4bb2-8531-6934f84aad40",
                  ],
                },
              },
              sort: { _createdAt: -1 },
              limit: 4,
            },
          },
        },
        {
          title: `wall_sections.main.last_minute`,
          config: {
            type: WALL_SLOT_TYPE.LAST_MINUTE,
            payload: {
              query: {
                $or: [
                  { isHotelFakeSupply: { $exists: false } },
                  { isHotelFakeSupply: false },
                ],
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
              },
              sort: { lockDate: 1 },
              limit: 1,
            },
          },
        },
        {
          title: "",
          config: {
            type: WALL_SLOT_TYPE.SOCIAL,
            payload: {},
          },
        },
        {
          title: "wall_sections.main.classic_mountain",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              scroll: true,
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
                collectionQuery: {
                  "_collectionCensus.locationType": "mountain",
                },
              },
              sort: { lockDate: -1 },
              limit: 4,
            },
          },
        },
        {
          title: `wall_sections.main.partners_2`,
          config: {
            type: WALL_SLOT_TYPE.PARTNER,
            payload: {
              query: {
                ...partnerFilterHotelDefault,
                _id: {
                  $in: [
                    "a5885082-a475-4bb6-906e-c6850ac8fdf3",
                    "b8889a81-4af3-4062-8aa6-8154a75759bf",
                    "eac3c030-748c-4e09-8941-730db7c3002c",
                    "d1680ec9-a53c-45a8-a51a-8f9c3053f647",
                  ],
                },
              },
              sort: { _createdAt: 1 },
              limit: 4,
            },
          },
        },
        {
          title: "wall_sections.main.classic_all",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
              },
              sort: { lockDate: -1 },
            },
          },
        },
      ],
    },
    {
      area: "stay-sea",
      categoryName: "wall_sections.stay-sea.title",
      icon: "sailing",
      separator: false,
      showFilter: false,
      slots: [
        {
          title: "wall_sections.stay-sea.classic_list.title",
          subtitle: "", // "wall.stay-sea.classic_list.subtitle",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
                collectionQuery: {
                  "_collectionCensus.locationType": "sea",
                },
              },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.stay-sea.partner.title",
          subtitle: "", // "wall.stay-sea.partner.subtitle",
          config: {
            type: WALL_SLOT_TYPE.PARTNER,
            payload: {
              query: {
                ...partnerFilterHotelDefault,
                "census.locationType": "sea",
              },
              sort: { _createdAt: -1 },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.generic.stay_request",
          config: {
            type: WALL_SLOT_TYPE.STAY_REQUEST,
            payload: {},
          },
        },
      ],
    },
    {
      area: "stay-mountain",
      categoryName: "wall_sections.stay-mountain.title",
      icon: "landscape",
      separator: false,
      showFilter: false,
      slots: [
        {
          title: "wall_sections.stay-mountain.classic_list.title",
          subtitle: "", // "wall.stay-sea.classic_list.subtitle",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
                collectionQuery: {
                  "_collectionCensus.locationType": "mountain",
                },
              },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.stay-mountain.partner.title",
          subtitle: "", // "wall.stay-mountain.partner.subtitle",
          config: {
            type: WALL_SLOT_TYPE.PARTNER,
            payload: {
              query: {
                ...partnerFilterHotelDefault,
                "census.locationType": "mountain",
              },
              sort: { _createdAt: -1 },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.generic.stay_request",
          config: {
            type: WALL_SLOT_TYPE.STAY_REQUEST,
            payload: {},
          },
        },
      ],
    },
    {
      area: "stay-lake",
      categoryName: "wall_sections.stay-lake.title",
      icon: "kayaking",
      separator: false,
      showFilter: false,
      slots: [
        // {
        //   title: "wall_sections.stay-lake.classic_list.title",
        //   subtitle: "", // "wall.stay-sea.classic_list.subtitle",
        //   config: {
        //     type: WALL_SLOT_TYPE.CLASSIC,
        //     payload: {
        //       query: {
        //         sellStatus: NFT_SELL_STATUS.FOR_SALE,
        //         collectionQuery: {
        //           "_collectionCensus.locationType": "lake",
        //         },
        //       },
        //       limit: 30,
        //     },
        //   },
        // },
        {
          title: "wall_sections.stay-lake.partner.title",
          subtitle: "", // "wall.stay-sea.partner.subtitle",
          config: {
            type: WALL_SLOT_TYPE.PARTNER,
            payload: {
              query: {
                ...partnerFilterHotelDefault,
                "census.locationType": "lake",
              },
              sort: { _createdAt: -1 },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.generic.stay_request",
          config: {
            type: WALL_SLOT_TYPE.STAY_REQUEST,
            payload: {},
          },
        },
      ],
    },
    {
      area: "stay-city",
      categoryName: "wall_sections.stay-city.title",
      icon: "apartment",
      separator: true,
      showFilter: false,
      slots: [
        {
          title: "wall_sections.stay-city.classic_list.title",
          subtitle: "", // "wall.stay-sea.classic_list.subtitle",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
                collectionQuery: {
                  "_collectionCensus.locationType": "city",
                },
              },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.stay-city.partner.title",
          subtitle: "", // "wall.stay-city.partner.subtitle",
          config: {
            type: WALL_SLOT_TYPE.PARTNER,
            payload: {
              query: {
                ...partnerFilterHotelDefault,
                "census.locationType": "city",
              },
              sort: { _createdAt: -1 },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.generic.stay_request",
          config: {
            type: WALL_SLOT_TYPE.STAY_REQUEST,
            payload: {},
          },
        },
      ],
    },
    {
      area: "experience",
      categoryName: "wall_sections.experience.title",
      icon: "nordic_walking",
      separator: false,
      showFilter: false,
      slots: [
        {
          title: "wall_sections.experience.section1",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              scroll: true,
              query: {
                ...isExperienceTak,
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
              },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.experience.section2",
          config: {
            type: WALL_SLOT_TYPE.OFFER,
            payload: {
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
                "payload.type": COLLECTION_TYPE.experience,
                collectionId: "a8f1806d-ff9b-431e-bab8-1d643d04615a",
              },
              sort: { lockDate: 1 },
              limit: 1,
            },
          },
        },
        {
          title: "wall_sections.experience.section3",
          subtitle: "", // "wall.stay-sea.classic_list.subtitle",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              scroll: true,
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
                "payload.type": COLLECTION_TYPE.experience,
                collectionId: "a8f1806d-ff9b-431e-bab8-1d643d04615a",
              },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.experience.section4",
          config: {
            type: WALL_SLOT_TYPE.LAST_MINUTE,
            payload: {
              query: {
                // sellStatus: NFT_SELL_STATUS.FOR_SALE,
                // "payload.type": COLLECTION_TYPE.experience,
                _id: "bde38c36-8d51-4a5c-a5a4-e56b4c101501",
              },
              limit: 1,
            },
          },
        },
        {
          title: "wall_sections.experience.section5",
          subtitle: "", // "wall.stay-sea.classic_list.subtitle",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              scroll: true,
              query: {
                collectionId: "528fb88a-6f6f-461b-af4f-7c351a4b999c",
              },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.experience.section7",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              scroll: true,
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
                "payload.type": COLLECTION_TYPE.experience,
                collectionId: "5dcf5d35-43e4-4091-95c1-08915d4d3b84",
              },
              limit: 30,
            },
          },
        },
        {
          title: "wall_sections.experience.section6",
          subtitle: "",
          config: {
            type: WALL_SLOT_TYPE.CLASSIC,
            payload: {
              scroll: true,
              query: {
                sellStatus: NFT_SELL_STATUS.FOR_SALE,
                "payload.type": COLLECTION_TYPE.experience,
                collectionId: "da395562-d07e-44aa-a198-be9101508317",
              },
              limit: 30,
            },
          },
        },
      ],
    },
  ],
};

export let WALL_CONFIG: iWall = WALL_CONFIG_PROD;
